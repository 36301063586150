<template>
  <div :style="{ display: 'flex' }">
    <div :class="'answerBox' + ' ' + answerBoxClass">
      <input
        type="text"
        minlength="1"
        required="required"
        :readonly="true"
        :value="value"
        @click="$emit('onClick')"
        :size="size + 1"
        :style="{ textAlign: 'center', color: 'inherit' }"
        :class="answerBoxClass"
        v-ripple
      />
    </div>
    <v-icon
      v-if="hintOn"
      class="hintIcon"
      @click="showWordHelpDialog = true"
      color="primary"
      >mdi-gesture-tap-hold</v-icon
    >
    <WordHelpDialog
      v-if="showWordHelpDialog"
      :visible="showWordHelpDialog"
      :word="hintWord"
      @close="showWordHelpDialog = false"
    ></WordHelpDialog>
  </div>
</template>

<script>
import WordHelpDialog from './WordHelpDialog'

export default {
  props: [
    'value',
    'size',
    'correct',
    'hintOn',
    'hintWord',
    'focused',
    'highlightCorrect'
  ],
  components: {
    WordHelpDialog
  },
  computed: {
    answerBoxClass() {
      if (this.value) {
        if (this.highlightCorrect) {
          if (this.correct) {
            return 'correct'
          } else {
            return 'incorrect'
          }
        } else {
          return 'has-value'
        }
      } else {
        if (this.focused) {
          return 'empty'
        } else {
          return 'non-empty'
        }
      }
    }
  },
  data() {
    return {
      showWordHelpDialog: false
    }
  },
  watch: {
    value: function (newValue) {
    }
  },
  methods: {}
}
</script>
<style scoped>
div.answerBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #000;
  font-size: x-large;
}
div.empty {
  animation: pulse-red-box 2s infinite;
}
.correct {
  background-color: var(--v-success-base);
  border-radius: 5px;
  color: white;
}
.incorrect {
  background-color: red;
  border-radius: 5px;
  color: white;
}
.hintIcon {
  box-shadow: 0 0 0 0 rgba(217, 84, 89, 1);

  border-radius: 40px;
}
@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(217, 84, 89, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(217, 84, 89, 0);
  }
}
@keyframes pulse-red-box {
  0% {
    background-color: rgba(217, 84, 89, 0.5);
  }
  70% {
    background-color: rgba(217, 84, 89, 0);
  }
  100% {
    background-color: rgba(217, 84, 89, 0.5);
  }
}

input.has-value {
  animation: text-appear 0.5s alternate ease-in-out;
}

@keyframes text-appear {
  0% {
    color: transparent;
  }
  100% {
    color: black;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
