<template>
  <span>
    <small style="font-weight: bold; text-decoration: italic" v-if="prefix"
      >{{ prefix }}&nbsp;</small
    >
    <span v-html="contentWithHighlightedText"></span>
  </span>
</template>
<script>
export default {
  props: ['statement', 'highlights', 'prefix', 'highlightStyle'],
  data: function () {
    return {}
  },
  computed: {
    highlightsCalc() {
      // const temp = []
      // this.highlights.forEach(t => {
      //   t.split(' ').forEach(t => temp.push(t))
      // })
      return this.highlights
    },
    contentWithHighlightedText() {
      var content = this.statement
      for (var i = 0; i < this.highlightsCalc.length; i++) {
        if (this.statement.includes('_')) {
          content = content.replace(new RegExp('_', 'i'), (match) => {
            return (
              '<span class="' +
              this.highlightStyle +
              '">' +
              this.highlightsCalc[i] +
              '</span>'
            )
          })
        } else {
          content = content.replaceAll(
            this.highlightsCalc[i],
            '<span class="' +
              this.highlightStyle +
              '">' +
              this.highlightsCalc[i] +
              '</span>'
          )
        }
      }
      return content
    }
  },
  methods: {},
  watch: {
    // disable all buttons on game over; re-enable all buttons on restart
    gameOver: function (newValue) {
      this.disabled = newValue
    }
  }
}
</script>
<style>
.highlightPrimary {
  color: var(--v-primary-base);
  font-weight: bold;
}
.highlightGreen {
  color: green;
  font-weight: bold;
}
</style>
