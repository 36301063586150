<template>
  <v-menu class="mr-2">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color || 'white'"
        plain
        v-bind="attrs"
        v-on="on"
        :style="{ padding: '0 16px 0 16px', height: 'auto', border: border }"
        class="font-weight-bold text-body-1 text-capitalize levelSelection"
      >
        {{ $t(levels[selectedLevel].text) }}
        <v-icon right :color="color || 'white'" class="ml-0"
          >mdi-chevron-down</v-icon
        >
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group v-model="selectedLevel">
        <v-list-item
          v-for="l in levels"
          :key="l.value"
          @click="$emit('onSelect', l.value)"
        >
          <v-list-item-title>{{ $t(l.text) }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
import DisplayImage from '../Image.vue'

export default {
  components: { DisplayImage },
  props: ['levels', 'color', 'border', 'initialLevel'],
  data: () => ({
    selectedLevel: 0
  }),
  mounted() {
    this.selectedLevel = this.initialLevel || 0
  },
  computed: {}
}
</script>
<style scoped>
.levelSelection >>> .v-btn__content {
  opacity: 1 !important;
}
</style>
