<template>
  <div>
    <v-row justify="center" dense>
      <v-col cols="auto">
        <v-row dense
          ><v-col :style="{ textAlign: 'center', fontSize: 'x-large' }">{{
            $t('Congratulations for successfully completing this exercise!')
          }}</v-col></v-row
        >
        <v-row dense
          ><v-col class="reportCardLabel">{{ $t('Level') }}</v-col
          ><v-col class="reportCardValue">{{ reportData.level }}</v-col></v-row
        >
        <v-row dense
          ><v-col class="reportCardLabel">{{ $t('Number of Questions') }}</v-col
          ><v-col class="reportCardValue">{{
            reportData.totalNumberOfQuestions
          }}</v-col></v-row
        >
        <v-row dense
          ><v-col class="reportCardLabel">{{ $t('Correct Answers') }}</v-col
          ><v-col class="reportCardValue">{{
            reportData.correctCount
          }}</v-col></v-row
        >
        <v-row dense
          ><v-col class="reportCardLabel">{{ $t('Score') }}</v-col
          ><v-col class="reportCardValue">{{ finalScore }}%</v-col></v-row
        >
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col xs="12" md="4">
        <v-img alt="Learn Gujarati" src="../../assets/dada.jpg"> </v-img>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { firebaseAnalytics } from '../../firebase'

export default {
  components: {},
  props: ['reportData'],
  data: () => ({}),
  computed: {
    finalScore() {
      return (
        (this.reportData.correctCount /
          this.reportData.totalNumberOfQuestions) *
        100
      ).toFixed(0)
    }
  },
  mounted() {
    firebaseAnalytics.logEvent('tutorial_complete', {
      level: this.reportData.level,
      score: this.finalScore,
      name: this.$route.name
    })
  }
}
</script>
<style scoped>
.reportCardLabel {
  text-align: right;
  font-size: large;
  border-right: 1px solid;
  font-weight: bold;
}
.reportCardValue {
  font-size: large;
  border-left: 1px solid;
  font-weight: bold;
}
</style>
