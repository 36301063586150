<template>
  <v-img
    :height="height"
    :max-height="maxHeight"
    :width="width"
    :contain="contain"
    :position="position"
    :src="imageUrl"
  />
</template>
<script>
import Utils from '../util/Utils'
export default {
  props: ['assetPath', 'height', 'width', 'contain', 'position', 'maxHeight'],
  data: function () {
    return {}
  },
  computed: {
    imageUrl() {
      return Utils.imgUrlByPath(this.assetPath)
    }
  }
}
</script>
