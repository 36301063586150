<template>
  <div class="volumeSection ml-3 mr-3">
    <RoundSlider
      v-model="volume"
      :rangeColor="$vuetify.theme.themes.light.primary.base"
      lineCap="butt"
      borderWidth="0"
      handleSize="10"
      radius="25"
      width="5"
      :min="min"
      :max="max"
      :startAngle="startAngle"
      :endAngle="endAngle"
      :showTooltip="false"
      :valueChange="volumeChange"
    />
    <v-icon
      v-if="!muted"
      :style="{ position: 'absolute' }"
      color="primary"
      medium
      @click="toggleAudioEffects()"
      >mdi-volume-high</v-icon
    >
    <v-icon
      v-else
      medium
      :style="{ position: 'absolute' }"
      color="primary"
      @click="toggleAudioEffects()"
      >mdi-volume-off</v-icon
    >
  </div>
</template>

<script>
import RoundSlider from 'vue-round-slider'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    RoundSlider
  },
  props: {
    min: {
      type: String,
      default: '0'
    },
    max: {
      type: String,
      default: '100'
    },
    default: {
      type: String,
      default: '50'
    },
    startAngle: {
      type: String,
      default: '0'
    },
    endAngle: {
      type: String,
      default: '360'
    },
    backgroundMusicSrc: {
      type: String,
      default: ''
    },
    pauseBackgroundMusic: {
      type: Boolean,
      defaule: false
    }
  },
  computed: {
    ...mapGetters(['sound'])
  },
  mounted() {
    this.volume = this.default
    const soundsContext = require.context(
      '../../assets/sounds',
      true,
      /\.(mp3|wav)$/
    )
    soundsContext.keys().forEach((asset) => {
      this.sounds[
        asset.replace('./', '').replace('.wav', '').replace('.mp3', '')
      ] = new Audio(soundsContext(asset))
    })

    if (this.backgroundMusicSrc) {
      this.backgroundMusic = new Audio(this.backgroundMusicSrc)
      this.backgroundMusic.loop = true
      this.backgroundMusic.play()
    }
  },
  beforeDestroy() {
    this.backgroundMusic?.pause()
  },
  data() {
    return {
      volume: 0,
      muted: false,
      sounds: {},
      backgroundMusic: undefined
    }
  },
  methods: {
    ...mapActions(['playSound']),
    toggleAudioEffects() {
      this.muted = !this.muted
      this.$emit('muted', this.muted)
      if (this.backgroundMusic) this.backgroundMusic.muted = this.muted
    },
    volumeChange() {
      this.$emit('volumeChange', this.volume)
      if (this.backgroundMusic) this.backgroundMusic.volume = this.volume / 100
      Object.values(this.sounds).forEach((s) => (s.volume = this.volume / 100))
    }
  },
  watch: {
    sound(newValue) {
      if (!this.muted && newValue) this.sounds[newValue]?.play()
      this.playSound('')
    }
  }
}
</script>

<style scoped>
.volumeSection {
  text-align: center;
  justify-content: center;
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 15px;
  align-items: center;
}
.volumeSection /deep/ .rs-path {
  stroke-width: 4px;
  stroke-dasharray: 2, 3;
  opacity: 0.7;
  stroke: var(--v-primary-base) !important;
  height: 46px;
  width: 46px;
}
</style>
