<template>
  <div>
    <!-- <div v-if="showIcon">
      <v-icon
        v-if="hintOn"
        :color="color || 'primary'"
        :medium="medium || false"
        :small="small || false"
        :large="large || false"
        :x-large="xLarge || false"
        :x-small="xSmall || false"
        @click="toggleHint()"
        >mdi-lightbulb-on</v-icon
      >

      <v-icon
        v-else
        :color="color || 'primary'"
        :medium="medium || false"
        :small="small || false"
        :large="large || false"
        :x-large="xLarge || false"
        :x-small="xSmall || false"
        @click="toggleHint()"
        >mdi-lightbulb-on-10</v-icon
      >
    </div> -->
    <div :style="{ display: 'flex' }">
      <div class="mr-2">Hint:</div>
      <v-btn-toggle mandatory v-model="hintStatus">
        <v-btn
          class="hintSwitch"
          :class="{ hintBackground: hintStatus == 0 }"
          x-small
        >
          OFF
        </v-btn>

        <v-btn
          class="hintSwitch"
          :class="{ hintBackground: hintStatus != 0 }"
          x-small
        >
          ON
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>
<script>
export default {
  props: ['color', 'small', 'medium', 'large', 'xLarge', 'xSmall', 'showIcon'],
  data() {
    return {
      hintOn: false,
      hintStatus: 0
    }
  },
  watch: {
    hintStatus(newValue, oldValue) {
      this.$emit('onHintChange', newValue != 0)
    }
  },
  methods: {}
}
</script>
<style scoped>
.hintBackground {
  background-color: var(--v-primary-base) !important;
  color: white !important;
}
.hintSwitch {
  padding-right: 5px !important;
  padding-left: 5px !important;
}
</style>
