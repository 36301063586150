<template>
  <v-dialog v-model="visible" max-width="300" persistent>
    <v-card>
      <v-card-title
        class="text-h5 white--text mb-5"
        :style="{ backgroundColor: 'var(--v-primary-base)' }"
      >
        {{ word.gu }}
      </v-card-title>
      <v-card-text
        v-for="langCode in Object.keys(word).filter(l => l !== 'gu')"
        :key="langCode"
      >
        <v-row class="mb-0 pb-0" align="center">
          <span class="text-h6 font-weight-bold mr-2"
            >{{ langs[langCode].text }}:</span
          >
          <span class="wordText">{{ word[langCode] }}</span>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="show = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    visible: { type: Boolean, default: false, required: true },
    word: { type: Object, required: true }
  },
  computed: {
    ...mapGetters(['langs']),
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>
<style scoped>
.wordText {
  text-shadow: 0 1px 1px #eee;
  font-size: 15px;
}
</style>
